import { React, View, Link, StyleSheet, Text, Touchable } from 'lib'
import { Theme, Settings } from 'app'

import { AppStore, PlayStore } from '../../assets/StoreAssets'
import FacebookEvents from 'actions/modules/FacebookEvents'
const Download: React.FC = () => {
  return (
    <>
      <View
        variant={['flexNoFill', 'column']}
        style={styles.innerWrapper}
      >
        <Text
          variant={[`h3`, 'alignSelfCenter']}
          text={'Download and start using'}
        />
        <Text
          variant={[`h3`, 'alignSelfCenter']}
          text={'Becon for free today'}
        />
        <View variant={['flexNoFill', 'justifyCenter']} style={styles.buttonWrapper}>
          <Touchable onPress={() => FacebookEvents.LeadEvent('appStore')}>
            <a href={Settings.APP_STORE_LINK} target='_blank' rel='noopener, noreferrer'>
              <AppStore bgColor='#000'/>
              <Text style={styles.button}>Download Becon on App Store</Text>
            </a>
          </Touchable>
          <Touchable onPress={() => FacebookEvents.LeadEvent('playStore')}>
            <a href={Settings.GOOGLE_PLAY_LINK} target='_blank' rel='noopener, noreferrer'>
              <PlayStore bgColor='#000'/>
              <Text style={styles.button}>Download Becon on Google Play</Text>
            </a>
          </Touchable>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  innerWrapper: {
    display: 'flex',
    ...Theme.marginVertical(12),
    [Theme.media.down('mid')]: {
      ...Theme.marginVertical(8),
      textAlign: 'center',
    },
  },
  buttonWrapper: {
    marginTop: Theme.spacing(6),
    gap: Theme.spacing(2),
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
      flexDirection: 'column',
      ...Theme.center,
    },
  },
  button: {
    display: 'none',
  },
})

export default Download
